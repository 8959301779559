
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  align-items: center;
  background: var(--cl-canadaLifeRed);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
