
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.progress {
  appearance: none;
  display: block;
  height: 0.7rem;
  position: static;
  width: 100%;

  &::-webkit-progress-bar {
    background-color: #e0dfdf;
  }

  &::-webkit-progress-value {
    background-color: var(--cl-deepTeal);
    transition: width $transitionCubic;
  }
}
