
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: grid;
  gap: var(--cl-space-m);
  margin-bottom: var(--cl-space-m);
}

.backLink {
  @extend %buttonBase;
  align-items: center;
  color: var(--cl-canadaLifeRed);
  display: inline-grid;
  font-size: 1.8rem;
  gap: 1rem;
  grid-auto-flow: column;
  justify-self: start;
  outline: none;

  &:focus {
    background: var(--cl-brightGold);
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    color: var(--cl-black);
    outline: 0;
  }

  &:focus:not(:focus-visible) {
    background: inherit;
    box-shadow: none;
    color: inherit;
  }

  svg {
    font-size: 2.4rem;
    left: -0.3em;
    margin-right: -0.3em;
    top: 1px;
  }
}
