
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: grid;
  gap: var(--cl-space-m);
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;

  svg {
    color: var(--cl-canadaLifeRed);
    font-size: 2.4rem;
  }
}

.title {
  @extend %h3;
  margin: 0;
}

.list {
  @extend %unstyledList;
  margin-bottom: var(--cl-space-xs);
}

.listItem {
  margin-bottom: var(--cl-space-m);

  &:last-child {
    margin: 0;
  }
}

.count {
  margin-right: var(--cl-space-xs);
}

.link {
  @include text-link($textColor: var(--cl-darkGrey));
  font-weight: 400;

  &.isHighlighted {
    @include text-link;
  }
}
