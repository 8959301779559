%input {
  --borderColor: var(--cl-canadaLifeGrey);
  --focusBorderColor: var(--cl-darkGrey);
  --focusColor: var(--cl-brightGold);
  --disabledColor: var(--cl-canadaLifeGrey);

  border: none;
  border-radius: $borderRadius;
  box-shadow: inset 0 0 0 1px var(--borderColor);
  display: flex;
  min-height: 4.4rem;
  outline: none;
  padding: 0 1.6rem;
  transition: $transition;

  &:focus,
  &.isFocused {
    box-shadow: inset 0 0 0 2px var(--focusBorderColor),
      0 0 0 4px var(--focusColor);
  }

  &.hasError {
    background-color: rgba(map-get($colors, "errorRed"), 0.1);
    box-shadow: inset 0 0 0 2px var(--cl-errorRed);
  }

  &.hasWarning {
    background-color: rgba(map-get($colors, "warningOrange"), 0.1);
    box-shadow: inset 0 0 0 2px var(--cl-warningOrange);
  }

  &.hasValue:not(:focus):not(.hasError) {
    box-shadow: inset 0 0 0 2px var(--borderColor);
  }

  &.isDisabled,
  [disabled] {
    background: rgba(map-get($colors, "canadaLifeGrey"), 0.2);
    box-shadow: inset 0 0 0 2px var(--disabledColor);
  }
}
