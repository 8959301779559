
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  @extend %input;
  cursor: pointer;
  font-size: 1.4rem;
  padding: 0;

  label {
    padding: 0 1rem;
  }

  &.checked:not(.isDisabled) {
    --focusBorderColor: transparent;
    background: var(--cl-canadaLifeRed);
    color: var(--cl-white);

    label::before {
      --borderColor: transparent;
    }
  }

  &.hasError {
    --disabledColor: var(--cl-errorRed);

    &:not(.checked) {
      background-color: transparent;
      color: var(--cl-errorRed);
    }

    label::before {
      --borderColor: var(--cl-errorRed);
    }

    &.isDisabled {
      opacity: 0.5;
    }
  }

  &.isDisabled {
    &,
    label {
      cursor: not-allowed;
    }

    label::before {
      --bgColor: transparent;
      --circleColor: transparent;
    }
  }
}
