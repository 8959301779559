
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.wrapper {
  background-color: var(--cl-white);
  border: 1px solid var(--cl-midGrey);
}

.header {
  align-items: center;
  border-bottom: 1px solid var(--cl-midGrey);
  display: flex;
}

.productHeading {
  font-weight: 600;
  padding: 0 2rem;
}

.productEligibility {
  align-items: center;
  background-color: var(--cl-deepTeal);
  display: flex;
  height: 5.2rem;
  justify-content: center;
  width: 5.2rem;

  &.ineligible {
    background-color: var(--cl-canadaLifeGrey);
  }
  &.best {
    background-color: var(--cl-deepGold);
  }

  svg {
    color: var(--cl-white);
    height: auto;
    width: 3.2rem;
  }
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  @include mq(m) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.feeWrapper {
  align-items: center;
  display: flex;

  @include mq($until: m) {
    margin-bottom: 1.2rem;
  }
}

.feeLabel {
  color: var(--cl-canadaLifeGrey);
  font-size: 1.2rem;
  font-weight: 600;
  margin-right: 1rem;
}

.feeValue {
  font-size: 1.8rem;
  font-weight: 600;
}

.buttonWrapper {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: auto auto;
}

.productInfoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style: none;
  margin: 0;
  padding: 0;

  @include mq(m) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.productInfo {
  border-bottom: 1px solid var(--cl-midGrey);
  border-right: 1px solid var(--cl-midGrey);
  padding: 1.2rem 1.5rem;

  &:nth-of-type(1),
  &:nth-of-type(3),
  &:nth-of-type(5),
  &:nth-of-type(7),
  &:nth-of-type(9) {
    border-right-width: 0;
  }

  &.mortgageType {
    grid-column: 1 / 3;
  }

  @include mq(m) {
    border-bottom: 1px solid var(--cl-midGrey);

    &:nth-of-type(1),
    &:nth-of-type(3),
    &:nth-of-type(5),
    &:nth-of-type(7) {
      border-right-width: 1px;
    }

    &:nth-of-type(4) {
      border-right-width: 0;
    }
  }
}

.productInfoTitle,
.productInfoValue,
.productInfoExtra {
  display: block;
  font-weight: 600;
}

.productInfoTitle {
  color: var(--cl-canadaLifeGrey);
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}
.productInfoValue {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}
.productInfoExtra {
  color: var(--cl-deepBlue);
  font-size: 1rem;
}
.warningIcon {
  color: var(--cl-canadaLifeRed);
  margin-left: 0.5rem;
}
