
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container,
.switchWrapper {
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: column;
  justify-content: start;
}

.container {
  --activeColor: var(--cl-deepTeal);
  --size: 1.7rem;
  $width: calc(var(--size) * 2.5);

  cursor: pointer;

  .label,
  .offLabel,
  .onLabel {
    @extend %formLabel;
  }

  .switch {
    background: rgba(map-get($colors, "darkGrey"), 0.25);
    border-radius: 50em;
    font-size: var(--size);
    height: 1em;
    width: $width;

    &::after {
      background: var(--activeColor);
      border-radius: 50%;
      box-shadow: 0 0 0 0 var(--cl-brightGold);
      content: "";
      height: 1.4em;
      left: 0;
      position: absolute;
      transform: translate(-0.2em, -0.2em);
      transition: left $transitionCubic, box-shadow $transitionCubic;
      width: 1.4em;
    }
  }

  &:not(.checked) {
    .offLabel {
      color: var(--activeColor);
    }
  }

  &.checked {
    .onLabel {
      color: var(--activeColor);
    }

    .switch::after {
      left: calc(#{$width} - 1em);
    }
  }

  &.isFocused .switch::after {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
  }

  &.isDisabled {
    --activeColor: var(--cl-canadaLifeGrey);
    cursor: not-allowed;
  }

  &.fullWidth {
    justify-content: space-between;
  }
}
