
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.logoGrid {
  display: grid;
  gap: 2ch;
  grid-auto-flow: column;
  grid-template-columns: 11ch 4ch 11ch;
  margin: auto;

  svg {
    color: var(--cl-canadaLifeRed);
    height: 7ch;
    width: auto;
  }

  .logoContainer {
    background-color: var(--cl-lightGrey);
    padding: 2ch 2ch;

    .imageWrapper {
      height: 100%;
      width: 100%;
    }
  }

  .arrowIcons {
    align-content: center;
    color: var(--cl-canadaLifeRed);
    display: grid;
    justify-content: center;

    svg {
      height: 3ch;
    }
  }
}

.container {
  display: grid;
  gap: var(--cl-space-m);
  margin: 0 auto;
  max-width: 60ch;
  text-align: center;
}

.description {
  margin-bottom: 0;
  max-width: 65ch;

  p {
    font-weight: 800;
  }
}

.actionButtonGroup {
  display: flex;
  justify-content: center;
}

.denyButton + .authoriseButton {
  margin-left: 10px;
}
