
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.steps {
  @extend %unstyledList;
  align-items: start;
  counter-reset: section;
  display: grid;
  gap: var(--cl-space-m);
  width: 100%;

  @include mq(m) {
    grid-auto-flow: column;
  }
}

.step {
  align-content: flex-start;
  background: rgba(map-get($colors, "canadaLifeGrey"), 0.1);
  counter-increment: section;
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
  padding: var(--cl-space-m);

  .title {
    @extend %h4;
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    justify-content: start;
    margin: 0;

    &::before {
      @extend %headerBase;
      color: var(--cl-canadaLifeRed);
      content: counter(section) ". ";
      font-size: 4.8rem;
      font-weight: 700;
    }
  }

  .content {
    margin-top: var(--cl-space-s);
  }

  .ctas {
    align-content: end;
    display: grid;
    gap: var(--cl-space-s);
    margin-top: var(--cl-space-l);
  }
}
