
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.input {
  @extend %input;
}

.toggleVisiblityButton {
  align-items: center;
  color: var(--cl-canadaLifeRed);
  display: grid;
  font-size: 1.2rem;
  font-weight: 600;
  gap: 0.5rem;
  grid-auto-flow: column;
  letter-spacing: 0.5px;
  position: absolute;
  right: 0;
  z-index: 1;

  &:focus {
    background-color: var(--cl-brightGold);
    box-shadow: 0 0 0 0.1em var(--cl-brightGold);
    color: var(--cl-black);
    outline: none;
  }

  // Undo focus styles for mouse/pointer interaction
  &:focus:not(:focus-visible) {
    background-color: inherit;
    box-shadow: none;
    color: var(--cl-canadaLifeRed);
  }

  svg {
    font-size: 1.8rem;
  }
}
