
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.top {
  background: var(--cl-white);
  color: var(--cl-darkGrey);
  display: grid;
  position: sticky;
  top: 0;
  transition: top $transitionCubic;
  z-index: map-get($zindex, "headerTop");

  &.isAuthenticated {
    height: var(--cl-headerHeight);

    &.isMinimised {
      top: calc(var(--cl-headerHeight) * -1);
    }
  }
}

.bottom {
  background: var(--cl-white);
  z-index: map-get($zindex, "headerBottom");
}

:global(.u-hideHeader) {
  .top {
    height: var(--cl-headerMastHeight);
    overflow: hidden;
  }
  .bottom {
    display: none;
  }
}

.envBanner {
  align-items: center;
  background: var(--cl-canadaLifeRed);
  display: flex;
  justify-content: space-between;
}

.envName {
  @extend %h5;
  color: var(--cl-white);
  margin: 0 var(--cl-space-l);
  text-transform: uppercase;
}
