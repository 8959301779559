
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.borrowerInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.dob {
  color: var(--cl-canadaLifeGrey);
}

.checkboxWrapper {
  margin: 2rem 0;
}
