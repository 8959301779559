
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.wrapper {
  display: inline-block;
}

.dropdownWrapper {
  background: var(--cl-white);
  box-shadow: 0 4px 8px 0 rgba(map-get($colors, "darkGrey"), 0.25);
  list-style: none;
  margin: 0;
  min-width: 100%;
  padding: 0;
  position: absolute;
  top: 100%;
  z-index: map-get($zindex, "main");

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.dropdownLink {
  @extend %bodyMedium;
  display: block;
  padding: 1.6rem var(--cl-space-m);
  white-space: nowrap;

  &:focus {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    outline: 0;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}

.dropdownButton {
  @extend %bodyMedium;
  width: 100%;
}
