
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: grid;
  gap: 0.8rem 0;
  grid-template:
    "label  .       " auto
    "input  tooltip " auto
    "text   .       " auto / 1fr auto;
}

.label {
  @extend %formLabel;
  grid-area: label;
}

.helperText {
  @extend %helperText;
  grid-area: text;
}

.errorText {
  @extend %helperText;
  color: var(--cl-errorRed);
  grid-area: text;
}

.warningText {
  @extend %helperText;
  color: var(--cl-warningOrange);
  grid-area: text;
}

.inputContainer {
  display: grid;
  gap: 1.5rem;
  grid-area: input;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  .containWithinTooltipBounds & {
    max-width: calc(100% - 4.4rem);
  }

  &.inputWithButton {
    grid-template-columns: auto max-content;
  }
}

.tooltip {
  align-items: center;
  color: var(--cl-canadaLifeRed);
  display: grid;
  font-size: 2.4rem;
  grid-area: tooltip;
  padding-left: 2rem;

  :global(.toggleButton) {
    border-radius: 50%;
    display: flex;

    &:focus {
      background: var(--cl-brightGold);
      box-shadow: inset 0 0 0 2px var(--cl-brightGold),
        0 0 0 2px var(--cl-brightGold);
      color: var(--cl-black);
    }

    // Undo focus styles for mouse/pointer interaction
    &:focus:not(:focus-visible) {
      background-color: inherit;
      box-shadow: inherit;
      color: inherit;
    }
  }
}
