
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentCell {
  flex: 1 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;

  svg {
    color: var(--cl-canadaLifeRed);
    font-size: 2.5rem;
  }
}

.iconsWrapper {
  align-items: center;
  display: grid;
  gap: var(--cl-space-m);
  grid-auto-flow: column;
}

.title {
  @extend %h3;
  margin: 0;
}

.collapseButton {
  &:focus {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    outline: 0;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}

.divider {
  margin: var(--cl-space-m) 0;
}

.tagsWrapper {
  padding-top: var(--cl-space-m);
}

.tag {
  background-color: var(--cl-deepTeal);
  color: var(--cl-white);
  display: inline-flex;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.5rem 0.8rem;

  svg {
    font-size: 1.5rem;
    margin-left: 0.6rem;
  }
}

.visually-hidden {
  @include visually-hidden;
}

.isHidden {
  display: none;
}
