
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.wrapper {
  display: flex;
}

.item {
  background-color: var(--cl-white);
  border: 1px solid var(--cl-midGrey);
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.2rem var(--cl-space-m);
  text-align: center;

  &:first-of-type {
    border-right: none;
  }
}

.fullWidth .item {
  flex: 1 1 50%;
}

.itemPrimary {
  color: var(--cl-deepTeal);
}

.itemSecondary {
  color: var(--cl-errorRed);
}
