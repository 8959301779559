
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.wrapper {
  display: inline-block;
}

.fullWidth {
  width: 100%;
}

.panel {
  background: var(--cl-white);
  box-shadow: 0 4px 8px 0 rgba(map-get($colors, "darkGrey"), 0.25);
  position: absolute;
  top: 100%;
  width: 25rem;
  z-index: map-get($zindex, "dropdownPanel");

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.panelHeader {
  border-bottom: 1px solid var(--cl-lightGrey);
  margin: 0 var(--cl-space-m);
  padding: var(--cl-space-m) 0;
}

.panelTitle {
  font-size: 2rem;
  font-weight: 600;
}

.panelSubmit {
  background-color: var(--cl-canadaLifeRed);
  color: var(--cl-white);
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0.8rem;
  width: 100%;

  &:focus {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    outline: 0;
  }

  // Undo focus styles for mouse/pointer interaction
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &[disabled] {
    background-color: var(--cl-canadaLifeGrey);
  }
}

.panelFooter {
  border-top: 1px solid var(--cl-lightGrey);
  margin: 0 var(--cl-space-m);
  padding: var(--cl-space-m) 0;
}

.dropdownLink {
  @extend %bodyMedium;
  display: block;
  padding: 1.6rem var(--cl-space-m);
  white-space: nowrap;

  &:focus {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    outline: 0;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}
