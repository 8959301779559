
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.datePicker {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr max-content;

  &.hasValue {
    gap: var(--cl-space-m);
  }
}

.inputContainer {
  @function calendar-icon($fill) {
    @return svg-encode(
      '<svg fill="#{$fill}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z"/></svg>'
    );
  }

  &::after {
    content: url(calendar-icon(map-get($colors, "canadaLifeRed")));
    font-size: 2rem;
    height: 1em;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-1.7rem, calc(-50% - 0.1rem));
    width: 1em;
  }

  &.isDisabled::after {
    content: url(calendar-icon(map-get($colors, "canadaLifeGrey")));
  }

  &:hover .clearButton {
    opacity: 1;
    transform: translate(-2rem, -50%) scale(1);
  }
}

.input {
  @extend %input;
  width: 100%;

  @include mq($until: s) {
    font-size: 1.3rem;
  }

  // We still show this as clicking it enables the native datepicker UI
  &::-webkit-calendar-picker-indicator {
    height: 3rem;
    margin-left: 0;
    margin-right: -1rem;
    opacity: 0;
    padding: 0;
    width: 3rem;
  }
}
