
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  @include clamp(padding, 3rem, clamp-vw-value(6rem), 6rem);
  background: var(--cl-canadaLifeRed);
  color: var(--cl-white);
  overflow: hidden;

  ::selection {
    background-color: var(--cl-brightGold);
    color: var(--cl-canadaLifeRed);
  }

  @include mq($until: m) {
    display: grid;
  }
}

.title {
  @extend %h2;

  $left-bracket: svg-encode(
    '<svg fill="#{map-get($colors, "white")}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 90"><path d="M0 0h20v11h-9v68h9v11H0V0z"/></svg>'
  );
  $right-bracket: svg-encode(
    '<svg fill="#{map-get($colors, "white")}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 90"><path d="M20 90H0V79h9V11H0V0h20v90z"/></svg>'
  );
  align-items: center;
  display: inline-grid;
  gap: 1.4rem;
  grid-auto-flow: column;
  justify-content: start;

  &::after,
  &::before {
    line-height: 0;
    width: 1.8rem;
  }

  &::before {
    content: url($left-bracket);
  }

  &::after {
    content: url($right-bracket);
  }
}

.image {
  left: 0;
  line-height: 0;
  margin: var(--cl-space-m) 0;
  user-select: none;

  @include mq($until: l) {
    left: 50%;
    margin-left: -120vw;
    min-width: 170vw;
  }

  @include mq($from: m, $until: l) {
    margin-left: -50vw;
    min-width: 100vw;
  }

  @include mq(m) {
    position: absolute;
    top: 20%;
  }
}

.lead {
  margin-bottom: var(--cl-space-l);

  @include mq(m) {
    max-width: 45%;
    padding-top: 10%;
  }
}
