
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: grid;
  gap: var(--cl-space-l) 1.5rem;

  @include mq($until: m) {
    max-width: 25rem;
  }
}

@include mq(m) {
  .container {
    grid-template-columns: repeat(6, 1fr);
  }

  .title {
    grid-column: 1 / -1;
  }

  .birthSex {
    grid-column: 1 / -4;
  }

  .dob {
    grid-column: 1 / 4;
  }

  .firstName {
    grid-column: 1 / 3;
  }

  .middleName {
    grid-column: 3 / 5;
  }

  .lastName {
    grid-column: 5 / 7;
  }

  .dobNoticeBannerContainer {
    grid-column: 1 / 5;
  }
}
