
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.tippy {
  $arrow-offset: 2.2rem;
  $arrow-size: 2rem;

  @extend %bodyLarge;
  background-color: var(--cl-amDeepBlue);
  border-radius: $borderRadius;
  color: var(--cl-white);
  outline: none;
  transition-property: transform, visibility, opacity;

  &[data-animation="shift-away"][data-state="hidden"] {
    opacity: 0;

    &[data-placement^="top"] {
      transform: translateY(10px);
    }

    &[data-placement^="bottom"] {
      transform: translateY(-10px);
    }

    &[data-placement^="left"] {
      transform: translateX(10px);
    }

    &[data-placement^="right"] {
      transform: translateX(-10px);
    }
  }

  @include mq(">s") {
    margin: 0.5rem;

    &[data-placement^="top"] > :global(.tippy-arrow) {
      bottom: 0;

      &::before {
        border-top-color: initial;
        border-width: $arrow-size * 0.5 $arrow-size * 0.5 0;
        bottom: -#{$arrow-size * 0.5};
        left: 0;
        transform-origin: center top;
      }
    }

    &[data-placement^="bottom"] > :global(.tippy-arrow) {
      top: 0;

      &::before {
        border-bottom-color: initial;
        border-width: 0 $arrow-size * 0.5 $arrow-size * 0.5;
        left: 0;
        top: -#{$arrow-size * 0.5};
        transform-origin: center bottom;
      }
    }

    &[data-placement^="left"] > :global(.tippy-arrow) {
      right: 0;

      &::before {
        border-left-color: initial;
        border-width: $arrow-size * 0.5 0 $arrow-size * 0.5 $arrow-size * 0.5;
        right: -#{$arrow-size * 0.5};
        transform-origin: center left;
      }
    }

    &[data-placement^="right"] > :global(.tippy-arrow) {
      left: 0;

      &::before {
        border-right-color: initial;
        border-width: $arrow-size * 0.5 $arrow-size * 0.5 $arrow-size * 0.5 0;
        left: -#{$arrow-size * 0.5};
        transform-origin: center right;
      }
    }

    :global(.tippy-arrow) {
      color: var(--cl-amDeepBlue);
      font-size: $arrow-size;
      height: 1em;
      width: 1em;

      &::before {
        border-color: transparent;
        border-style: solid;
        content: "";
        position: absolute;
      }
    }
  }

  :global(.tippy-content) {
    padding: 2.5rem 3rem;
  }

  a {
    @include text-link($textColor: var(--cl-white));
  }
}

// Using :global preserves the class name so we can target it outside of this component
.container :global(.toggleButton) {
  display: flex;
  outline: none;
}

.closeIconContainer {
  font-size: 2rem;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;

  &:focus {
    color: var(--cl-brightGold);
  }

  // Undo focus styles for mouse/pointer interaction
  &:focus:not(:focus-visible) {
    color: inherit;
  }
}

.closeButtonContainer {
  margin-top: 4rem;

  @include mq(">s") {
    display: none;
  }
}

.title {
  @extend %h5;
  margin-bottom: 2.5rem;
}
