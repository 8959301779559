
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.title {
  @extend %container;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: var(--cl-containerGutter);
  justify-content: space-between;
  padding: var(--cl-containerGutter);

  .heading {
    @extend %h1;
    column-gap: 1rem;
    display: flex;
    flex-flow: row wrap;

    h1 {
      margin: 0;
    }

    .applicationNumber {
      display: flex;
      font-weight: 400;
      gap: 1rem;

      &::before {
        color: var(--cl-canadaLifeRed);
        content: "+";
      }
    }
  }
}
