
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.mast {
  @extend %bodySmall;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  display: grid;
  grid-auto-flow: column;
  height: 3.3rem;
  justify-content: space-between;
  padding: 0 var(--cl-containerGutter);

  dl {
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    margin: 0;

    dd {
      margin-inline-start: 0.5ch;
    }

    .openState {
      color: var(--cl-darkRed);
      font-weight: 600;

      &.isOpen {
        color: var(--cl-deepTeal);
      }
    }

    .openingHoursTitle {
      margin-inline-start: 0.5ch;

      @include mq($until: m) {
        @include visually-hidden;
      }
    }

    .openingHoursContent {
      @include mq($until: m) {
        @include visually-hidden;
      }
    }
  }

  ul {
    @extend %unstyledList;
    @include clamp(gap, 1.5rem, clamp-vw-value(4rem), 4rem);
    display: grid;
    font-weight: 600;
    grid-auto-flow: column;

    li {
      align-items: center;
      display: grid;
      gap: 0.8rem;
      grid-auto-flow: column;
    }

    a {
      align-items: center;
      color: var(--cl-canadaLifeRed);
      outline: none;

      @include mq(m) {
        display: grid;
        gap: 1.2rem;
        grid-auto-columns: max-content;
        grid-auto-flow: column;
      }
    }

    svg {
      font-size: 2rem;
    }

    .linkLabel {
      @include text-link($textColor: var(--cl-darkGrey));
      line-height: 1.5;

      @include mq($until: m) {
        @include visually-hidden;
      }
    }

    .registerLinkContainer {
      display: none;

      @include mq(m) {
        display: contents;
      }
    }
  }
}
