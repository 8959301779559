
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.layout {
  margin: 0 auto;
  max-width: var(--cl-containerMaxWidth);
  width: 100%;
}
