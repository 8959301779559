
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.paginationWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.paginationText {
  font-size: 1.4rem;
  font-weight: 600;
}
