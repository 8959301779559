%container {
  --maxWidth: var(--cl-containerMaxWidth);
  --gutter: var(--cl-containerGutter);

  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--maxWidth) + var(--gutter) * 2);
  padding: 0 var(--gutter);
  width: 100%;
}
