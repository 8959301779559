
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  &::after {
    content: "%";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-2rem, -50%);
  }
}

.input {
  @extend %input;
  padding-right: 4rem;
  width: 100%;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
}
