
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  align-items: center;
  display: grid;
  gap: 1.5rem;
  grid-template: "cancel continue" auto / 1fr 1fr;
  margin: var(--cl-space-l) 0;

  @include mq(s) {
    @include clamp(padding-left, 2rem, clamp-vw-value(6rem), 6rem);
    @include clamp(padding-right, 2rem, clamp-vw-value(6rem), 6rem);
  }

  @include mq(m) {
    grid-template-columns: max-content 1fr;
  }

  &.saveAndExit {
    grid-template:
      "saveAndExit  continue" auto
      "cancel       cancel  ";

    @include mq(m) {
      grid-template: "saveAndExit cancel continue" max-content / max-content max-content 1fr;
    }
  }

  button,
  a {
    @include mq($until: m) {
      max-width: 16rem;
      white-space: nowrap;
      width: 100%;
    }

    @include mq(m) {
      min-width: 16rem;
    }
  }
}

.saveAndExit {
  grid-area: saveAndExit;
}

.cancel {
  grid-area: cancel;

  @include mq($until: m) {
    text-align: center;
  }
}

.continue {
  display: flex;
  grid-area: continue;
  justify-content: flex-end;
}
