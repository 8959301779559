
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.main {
  align-items: center;
  display: grid;
  flex: 1;
  grid-template: "logo breadcrumbToggle burger" / min-content auto max-content;
  padding: var(--cl-containerGutter);
  row-gap: 2rem;
  @include clamp(column-gap, 2rem, clamp-vw-value(6rem), 6rem);

  .logo {
    grid-area: logo;

    :global(.canadaLifeStackedLogo) {
      height: 5rem;
      width: auto;

      @include mq(">s") {
        display: none;
      }
    }

    :global(.canadaLifeMainLogo) {
      height: auto;
      width: 9.8rem;

      @include mq($until: ">s") {
        display: none;
      }
    }
  }

  .burger {
    grid-area: burger;
    justify-self: end;
  }
}
