
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: flex;
  flex-flow: column;
  min-height: 17rem;
  padding: 1.5rem 2rem;

  &.primary {
    background-color: var(--cl-canadaLifeRed);
    color: var(--cl-white);
  }

  &.secondary {
    background-color: var(--cl-amDeepBlue);
    color: var(--cl-white);
  }

  &.tertiary {
    background-color: var(--cl-lightGrey);
  }
}

.title {
  @extend %h3;
  margin-bottom: 1.5rem;
}

.value {
  @extend %headerBase;
  font-size: 4.4rem;
  font-weight: 700;
  margin-top: auto;
}

.description {
  margin-bottom: 1rem;
}
