
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.bracketInfo {
  $left-bracket: svg-encode(
    '<svg fill="#{map-get($colors, "canadaLifeRed")}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 90"><path d="M0 0h20v11h-9v68h9v11H0V0z"/></svg>'
  );
  $right-bracket: svg-encode(
    '<svg fill="#{map-get($colors, "canadaLifeRed")}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 90"><path d="M20 90H0V79h9V11H0V0h20v90z"/></svg>'
  );
  display: grid;
  font-family: $secondaryFontStack;
  gap: 1.4rem;
  grid-auto-flow: column;
  justify-content: start;

  &::after,
  &::before {
    line-height: 0;
    width: 1rem;
  }

  &::before {
    content: url($left-bracket);
  }

  &::after {
    content: url($right-bracket);
  }

  .container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    line-height: 1;
  }

  .label {
    color: var(--cl-canadaLifeGrey);
    font-size: 1.2rem;
  }

  .value {
    font-size: 2.6rem;
    font-weight: 700;
  }
}
