
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.controlWrapper {
  margin-bottom: 2rem;

  &:last-of-type {
    margin: 0;
  }
}

.radio {
  + label::before {
    --borderColor: var(--cl-canadaLifeGrey);
    --bgColor: var(--cl-white);
    --circleColor: var(--cl-deepTeal);

    background: var(--circleColor);
    box-shadow: inset 0 0 0 16px var(--bgColor), 0 0 0 1px var(--borderColor);
  }

  &:checked + label::before {
    box-shadow: inset 0 0 0 6px var(--bgColor), 0 0 0 2px var(--borderColor);
  }

  &.isDisabled + label {
    cursor: not-allowed;
    opacity: 0.5;

    &::before {
      --bgColor: transparent;
      --circleColor: transparent;
    }
  }
}

.specificValueWrapper {
  margin: 2.6rem 0;
}
