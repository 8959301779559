
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.notice {
  background-color: var(--cl-white);
  border: 1px solid var(--noticeColor);
  display: grid;
  grid-template-columns: min-content auto min-content;
  scroll-margin-top: calc(var(--cl-headerHeight) + var(--cl-space-l));

  .iconContainer {
    background-color: var(--noticeColor);
    display: flex;
    justify-content: center;
    width: 2.8rem;

    svg {
      color: var(--cl-white);
      font-size: 2rem;
      margin-top: 1rem;
    }
  }

  .contentContainer {
    padding: 1.2rem 2rem;

    &.hasCloseButton {
      padding-right: 0;
    }
  }

  .title {
    display: block;
    font-weight: 600;
    margin-bottom: 0.3rem;
  }

  .content {
    font-size: 1.4rem;
    font-weight: 600;

    &.hasTitle {
      font-weight: inherit;
    }

    a {
      @extend %textLink;
    }
  }

  .closeButton {
    align-items: center;
    color: var(--cl-canadaLifeRed);
    display: flex;
    font-size: 2rem;
    outline: none;
    padding: 1rem;

    &:focus {
      background-color: var(--cl-brightGold);
      color: var(--cl-black);
    }

    // Undo focus styles for mouse/pointer interaction
    &:focus:not(:focus-visible) {
      background-color: inherit;
      color: inherit;
    }
  }

  &.alert {
    --noticeColor: var(--cl-errorRed);
  }

  &.warning {
    --noticeColor: var(--cl-warningOrange);
  }

  &.success {
    --noticeColor: var(--cl-deepTeal);
  }

  &.information {
    --noticeColor: var(--cl-deepBlue);
  }
}
