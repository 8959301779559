
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  margin-bottom: var(--cl-space-l);
}

.toggleShowErrorsButton {
  @extend %textLink;
}

.errorList {
  list-style: none;
  padding: 0;
}

.errorText {
  white-space: pre-wrap;
}
