
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  align-items: center;
  display: grid;
  gap: 2.5rem;
  margin: auto;
  max-width: 93rem;
  width: 100%;

  @include mq(m) {
    grid-template-columns: 1fr 1fr;
  }

  .intro {
    :global(.canadaLifeMainLogo) {
      --primaryColor: var(--cl-white);
      --secondaryColor: var(--cl-white);
      --tertiaryColor: transparent;
      @include clamp(width, 15.7rem, clamp-vw-value(21.8rem), 21.8rem);
      height: auto;
      margin-bottom: 5.5rem;
    }

    h1 {
      @extend %h1;
      margin-bottom: 1.5rem;
    }

    h2 {
      @extend %h2;
      @include clamp(margin-bottom, 2.5rem, clamp-vw-value(4rem), 4rem);
    }

    p {
      @include clamp(margin-bottom, 3rem, clamp-vw-value(4rem), 4rem);
    }

    .link {
      @extend %textLink;
      color: var(--cl-white);

      &:focus {
        color: var(--cl-black);
      }

      &:focus:not(:focus-visible) {
        color: inherit;
      }
    }

    @include mq($until: m) {
      @include clamp(max-width, 27rem, clamp-vw-value(60rem), 60rem);
    }
  }
}
