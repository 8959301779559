
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
$breadcrumbsBreakpoint: "m";

// All viewports
.breadcrumbs {
  overflow: auto;

  ol {
    @extend %unstyledList;
    display: grid;
    font-size: 1.8rem;
    white-space: nowrap;
  }

  li {
    display: grid;
    grid-auto-flow: column;
    line-height: 1.4;
  }

  .chevron {
    align-items: center;
    display: flex;
  }
}

.toggleButton {
  align-items: center;
  display: grid;
  gap: var(--cl-space-s);
  grid-area: breadcrumbToggle;
  grid-auto-flow: column;
  justify-content: start;
  justify-self: start;
  outline: none;

  &:focus,
  &:focus-within {
    background-color: var(--cl-brightGold);
    box-shadow: 0 0 0 0.1em var(--cl-brightGold);

    .chevron,
    span {
      color: var(--cl-black);
    }
  }

  // Undo focus styles for mouse/pointer interaction
  &:focus:not(:focus-visible) {
    background-color: inherit;
    box-shadow: none;

    .chevron {
      color: var(--iconColor);
    }
  }

  .chevron {
    --iconColor: var(--cl-canadaLifeRed);
    color: var(--iconColor);
    display: flex;
    font-size: 2.4rem;
    transition: transform $transitionCubic;
  }

  &.isOpen .chevron {
    transform: rotate(180deg);
  }
}

// Until large viewports
@include mq($until: $breadcrumbsBreakpoint) {
  .breadcrumbs {
    @include overlay;
    align-self: start;
    background: #fbfbfb;
    display: none;
    gap: var(--cl-space-l);
    grid-template-rows: 1fr min-content;
    padding: var(--cl-containerGutter);
    z-index: map-get($zindex, "breadcrumbs");

    &.isOpen {
      display: grid;
    }

    ol {
      grid-auto-rows: 9.9rem;
    }

    .chevron {
      font-size: 2.6rem;
      height: 100%;
      padding-right: var(--cl-space-l);
      position: absolute;
      right: 0;
    }
  }

  .breadcrumbItem {
    align-items: center;
    color: var(--cl-canadaLifeRed);
    display: flex;

    &.isCurrent {
      color: var(--cl-darkGrey);

      .chevron {
        display: none;
      }

      .breadcrumbLink {
        border-bottom: 3px solid;
      }
    }
  }

  .breadcrumbLink {
    align-items: center;
    border-bottom: 1px solid #f0f0ef;
    color: var(--textColor);
    display: flex;
    font-weight: 600;
    height: 100%;
    letter-spacing: 0.2px;
    outline: none;

    &:focus,
    &:focus-within {
      background-color: var(--cl-brightGold);
      border-bottom-color: transparent;
      box-shadow: 0 0 0 0.5em var(--cl-brightGold);
      color: var(--cl-black);

      ~ .chevron {
        color: var(--cl-black);
      }
    }

    // Undo focus styles for mouse/pointer interaction
    &:focus:not(:focus-visible) {
      background-color: inherit;
      border-bottom-color: inherit;
      box-shadow: none;
      color: var(--cl-canadaLifeRed);

      ~ .chevron {
        color: currentColor;
      }
    }
  }
}

// Large viewports
@include mq($breadcrumbsBreakpoint) {
  .breadcrumbs {
    ol {
      font-size: 1.4rem;
      grid-auto-flow: column;
      justify-content: start;
    }

    li {
      align-items: center;
      gap: 1rem;

      &:not(:last-of-type) {
        margin-right: 1rem;
      }

      &:last-of-type {
        .chevron {
          display: none;
        }
      }
    }
  }

  .breadcrumbLink {
    @include text-link($textColor: var(--cl-darkGrey));
    font-weight: normal;
  }

  .toggleButton,
  .closeButtonContainer {
    display: none;
  }
}
