
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.controlWrapper {
  margin-bottom: 2rem;

  &:last-of-type {
    margin: 0;
  }
}

.checkbox {
  + .label::before {
    $tick: svg-encode(
      '<svg fill="#{map-get($colors, "deepTeal")}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>'
    );
    background: var(--cl-white) url($tick) no-repeat center 2rem / 1.8rem;
  }

  &:checked + .label::before {
    border-width: 2px;
  }
}
