
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  background: var(--cl-lightGrey);
  display: grid;
  gap: var(--cl-space-m);
  padding: inherit;
}

.searchLogo {
  color: var(--cl-canadaLifeRed);
  text-align: center;

  svg {
    font-size: 3.4rem;
  }
}

.resultsText {
  font-weight: 600;
  text-align: center;
}

.searchedTerm {
  color: var(--cl-deepTeal);
  font-weight: 600;
  text-align: center;
}

.hint {
  font-size: 14px;
  text-align: center;
}

.clearSearch {
  color: var(--cl-canadaLifeRed);
  font-weight: 600;
  text-align: center;

  svg {
    font-size: 3.4rem;
    padding-left: 1rem;
  }
}
