
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.optionGroupTitle {
  font-weight: 600;
  padding: var(--cl-space-m) var(--cl-space-m) 0;
}

.controlsWrapper {
  @extend %unstyledList;
  padding: var(--cl-space-m);
}

.listItem {
  margin-bottom: var(--cl-space-m);

  &:last-of-type {
    margin-bottom: 0;
  }
}

[type="radio"] {
  &:checked + .radioLabel::before {
    --borderColor: var(--cl-canadaLifeGrey);
    --bgColor: var(--cl-white);
    --circleColor: var(--cl-deepTeal);

    background: var(--circleColor);
    border-radius: 50%;
    box-shadow: inset 0 0 0 6px var(--bgColor), 0 0 0 2px var(--borderColor);
  }

  &:focus + .radioLabel {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    outline: 0;
  }

  // Undo focus styles for mouse/pointer interaction
  &:focus:not(:focus-visible) + .radioLabel {
    box-shadow: none;
  }
}
