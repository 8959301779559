
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.heading {
  display: block;
  font-weight: 600;
  margin: 0 0 var(--cl-space-s);
}

.hint {
  @extend %bodyMedium;
  margin-bottom: var(--cl-space-m);
  margin-top: var(--cl-space-s);
}

.gridWrapper {
  display: grid;
  grid-template-areas:
    "input"
    "hint"
    "button";
  grid-template-columns: 1fr;

  @include mq($from: m) {
    column-gap: 1.5rem;
    grid-template-areas:
      "input button"
      "hint hint";
    grid-template-columns: 35rem auto;
  }
}

.inputWrapper {
  grid-area: input;
}

.buttonWrapper {
  align-items: center;
  display: flex;
  grid-area: button;

  button {
    min-height: 4.4rem !important;

    @include mq($until: m) {
      width: 100%;
    }
  }
}

.hintWrapper {
  grid-area: hint;
}
