
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: grid;
  gap: var(--cl-space-m);
}

.title {
  @extend %h3;
  margin: 0;
}

.hint {
  @extend %bodyMedium;
  margin-bottom: var(--cl-space-m);
  margin-top: var(--cl-space-s);
}
