
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
@keyframes fade-and-move-up {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-and-move-down {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10rem);
  }
}

.modal {
  z-index: map-get($zindex, "modal");

  .content {
    animation: fade-and-move-up $transitionTimingSlow $transitionCubicEasing
      forwards;
  }

  .overlay,
  &.isClosing .overlay {
    opacity: 0;
  }

  &.isOpen .overlay {
    opacity: 1;
  }

  &.isClosing .content {
    animation-name: fade-and-move-down;
  }

  &.hasTitle {
    .header {
      margin-bottom: 2.6rem;
    }

    .closeButton {
      margin-top: 0.8rem;
      position: relative;
    }
  }

  .overlay {
    @include overlay;
    align-items: flex-end;
    background: rgba(map-get($colors, "black"), 0.5);
    display: flex;
    height: 100vh; // Override overlay
    justify-content: center;
    top: 0; // Override overlay
    transition: opacity $transitionCubic;

    @include mq(">s") {
      @include clamp(padding, 2rem, clamp-vw-value(7.5rem), 7.5rem);
      align-items: center;
    }
  }

  .content {
    @include clamp(padding, 4rem, clamp-vw-value(6rem), 6rem);
    background: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    max-height: 100%;
    max-width: 80rem;
    outline: none;
    overflow: auto;
    width: 100%;
  }

  .closeButton {
    align-items: center;
    background: var(--cl-white);
    display: grid;
    flex-shrink: 0;
    gap: 1rem;
    grid-auto-flow: column;
    outline: none;
    position: absolute;
    right: 0;

    span {
      @extend %textLink;
    }

    &:focus,
    &:hover {
      span {
        background-position: 0 100%;
        background-size: 100% 1px;
        color: var(--cl-darkRed);
      }
    }

    svg {
      border-radius: 50%;
      color: var(--cl-canadaLifeRed);
      font-size: 2.6rem;
    }

    &:not(:hover) svg {
      --bgColor: var(--cl-white);
      --borderColor: currentColor;
      --crossColor: currentColor;
    }

    &:focus svg {
      --bgColor: var(--cl-darkRed);
      --borderColor: var(--bgColor);
      --crossColor: var(--cl-white);
      box-shadow: inset 0 0 0 2px var(--cl-brightGold),
        0 0 0 2px var(--cl-brightGold);
    }

    &:focus:not(:focus-visible) svg {
      --bgColor: inherit;
      --borderColor: inherit;
      --crossColor: inherit;
      box-shadow: none;
    }
  }

  .title {
    @extend %h1;
  }

  .header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
}
