
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.title {
  @extend %h3;
  margin: 0;
  padding-bottom: var(--cl-space-m);
}

.link {
  @include text-link($textColor: var(--cl-canadaLifeRed));
  font-size: 1.8rem;
  font-weight: bold;
}

.imageContainer {
  padding-top: 40%;
}

.emptyImageUrl {
  background-color: var(--cl-transparentCanadaLifeRed);
}

.copyContainer {
  padding: var(--cl-space-m);
}
