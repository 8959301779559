
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  $search: svg-encode(
    '<svg fill="#{map-get($colors, "canadaLifeRed")}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>'
  );

  &::after {
    content: url($search);
    font-size: 2rem;
    height: 1em;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(1.7rem, -50%);
    width: 1em;
  }

  &:hover .clearButton {
    opacity: 1;
    transform: translate(-2rem, -50%) scale(1);
  }
}

.input {
  @extend %input;
  padding-left: 4.3rem;
  width: 100%;

  &::-webkit-search-cancel-button {
    appearance: none;
  }
}

.clearButton {
  display: flex;
  opacity: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-2rem, -50%) scale(0.9);
  transition: $transitionCubic;

  svg:not(:hover) {
    --bgColor: var(--cl-white);
    --borderColor: currentColor;
    --crossColor: currentColor;
  }

  &:focus svg {
    --bgColor: var(--cl-brightGold);
    --borderColor: var(--bgColor);
    --crossColor: var(--cl-black);
  }

  &:focus:not(:focus-visible) svg {
    --bgColor: inherit;
    --borderColor: inherit;
    --crossColor: inherit;
  }
}
