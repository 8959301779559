
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.toggleButton {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-area: burger;
  grid-auto-flow: column;
  justify-self: end;
  outline: none;

  span {
    font-family: $secondaryFontStack;
    font-weight: 500;
  }

  svg {
    color: var(--cl-canadaLifeRed);
    font-size: 2.2rem;
  }

  &:focus {
    background-color: var(--cl-brightGold);
    box-shadow: 0 0 0 0.1em var(--cl-brightGold);

    svg {
      color: var(--cl-black);
    }
  }

  // Undo focus styles for mouse/pointer interaction
  &:focus:not(:focus-visible) {
    background-color: inherit;
    box-shadow: none;

    svg {
      color: var(--cl-canadaLifeRed);
    }
  }
}

.overlay {
  @extend %overlay;
  background: rgba(map-get($colors, "black"), 0.5);
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: opacity $transition;

  &.menuIsOpen {
    opacity: 1;
    pointer-events: all;
  }
}

.menu {
  @extend %overlay;
  background: var(--cl-canadaLifeRed);
  color: var(--cl-white);
  display: grid;
  grid-auto-rows: min-content auto min-content;
  overflow: auto;
  transform: translateX(100%);
  transition: transform $transitionCubic;

  &.menuIsOpen {
    transform: none;
  }

  @include mq(">s") {
    left: auto;
    right: 0;
    width: 28.5rem;
  }

  .header {
    @extend %h6;
    padding: 3rem;
  }

  .userFullName {
    @extend %h2;
    display: block;
    margin-top: 1rem;
  }

  .nav {
    margin: 0 1rem;
  }

  .link {
    @extend %buttonLarge;
    align-items: center;
    display: flex;
    justify-content: space-between;
    outline: none;
    padding: 2rem;
    width: 100%;

    &:not(.logOutLink)::after {
      background: var(--cl-darkGrey);
      bottom: 0;
      content: "";
      height: 1px;
      left: 2rem;
      position: absolute;
      width: calc(100% - 4rem);
    }

    &:focus,
    &:focus-within {
      background-color: var(--cl-brightGold);
      color: var(--cl-black);
    }

    // Undo focus styles for mouse/pointer interaction
    &:focus:not(:focus-visible) {
      background-color: inherit;
      color: inherit;
    }

    svg {
      font-size: 2rem;
    }
  }

  .footer {
    background: var(--cl-darkRed);
    margin-top: 3rem;
    padding: 1rem;
  }
}
