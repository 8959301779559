
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.footer {
  @extend %bodySmall;
  padding-bottom: var(--cl-space-xl);
  @include clamp(padding-top, 6rem, clamp-vw-value(12rem), 12rem);

  .container {
    @extend %container;
  }

  a {
    @extend %textLink;
  }

  p {
    font: inherit;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: 600;
  }

  svg {
    bottom: 0;
    color: rgba(map-get($colors, "darkRed"), 0.32);
    height: auto;
    position: absolute;
    width: 100%;
  }

  &:not(.isAuthenticated) a {
    color: var(--cl-white);

    &:focus {
      color: var(--cl-black);
    }

    &:focus:not(:focus-visible) {
      color: inherit;
    }
  }

  &.isAuthenticated {
    svg {
      color: rgba(map-get($colors, "darkGrey"), 0.03);
    }
  }
}
