
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.panel {
  @include clamp(padding, 2rem, clamp-vw-value(6rem), 6rem);
  background: var(--cl-white);
  color: var(--cl-darkGrey);
}

.small {
  padding: var(--cl-space-l) var(--cl-space-m);
}

.blog {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: 0;
}
