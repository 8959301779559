
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.layout {
  background-color: var(--cl-canadaLifeRed);
  color: var(--cl-white);
  display: flex;
  flex-flow: column;
  min-height: 100vh;

  main {
    display: flex;
    flex: 1;
    flex-flow: column;
    overflow-x: hidden;
    padding: var(--cl-space-l) var(--cl-space-m);
    z-index: map-get($zindex, "main");
  }

  &:not(.isAuthenticated) {
    main,
    footer {
      ::selection {
        background-color: var(--cl-brightGold);
        color: var(--cl-canadaLifeRed);
      }
    }
  }

  &.isAuthenticated {
    background-color: #efefef;
    color: var(--cl-darkGrey);
  }

  &.isError {
    background-color: var(--cl-canadaLifeRed);
    color: var(--cl-white);

    h1 {
      color: var(--cl-darkGrey);
    }
  }
}

.skipLink {
  @include visually-hidden;
}
