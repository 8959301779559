
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.listItem {
  margin-bottom: var(--cl-space-m);

  &:last-of-type {
    margin-bottom: 0;
  }
}

[type="checkbox"] {
  &:checked:focus + .checkboxLabel {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    outline: 0;

    &::before {
      border-color: var(--cl-canadaLifeGrey);
    }
  }

  &:focus + .checkboxLabel {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    outline: 0;
  }

  // Undo focus styles for mouse/pointer interaction
  &:focus:not(:focus-visible) + .checkboxLabel {
    box-shadow: none;
  }
}

.controlsWrapper {
  @extend %unstyledList;
  padding: var(--cl-space-m);
}
