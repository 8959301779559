
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: var(--cl-space-l);

  @include mq($until: m) {
    align-items: center;
    flex-direction: column;
    margin-top: var(--cl-space-m);
  }
}

.disabled {
  opacity: 0.7;
}

.controlWrapper {
  align-items: center;
  display: flex;

  @include mq($until: m) {
    margin-bottom: var(--cl-space-m);
  }
}

.button {
  align-content: center;
  background-color: var(--cl-canadaLifeRed);
  border-radius: 50%;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-left: 1rem;
  width: 2rem;

  &:first-of-type {
    margin-left: 2rem;
  }

  &:focus {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    outline: 0;
  }

  svg {
    color: var(--cl-white);
    font-size: 2rem;
  }
}

.text {
  @extend %bodyMedium;
  white-space: nowrap;
}

.select {
  @extend %bodyMedium;
  background-color: var(--cl-white);
  background-position-x: right;
  background-size: 2rem;
  margin-left: 1.2rem;
  padding: 0.5rem 3rem 0.5rem 1rem;

  &:focus {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    outline: 0;
  }
}
