
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  &::after {
    content: "£";
    font-size: 1.4rem;
    font-weight: 700;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(1.2rem, -50%);
  }
}

.input {
  @extend %input;
  padding-left: 3rem;
  width: 100%;
}
