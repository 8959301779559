
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentCell {
  flex: 1 0 auto;
}

.actionCell {
  flex: 0 1 auto;
  margin-top: 4rem;
}

.header {
  display: flex;
  justify-content: space-between;

  svg {
    color: var(--cl-canadaLifeRed);
    font-size: 2.5rem;
  }
}

.title {
  @extend %h3;
}

.title,
.description {
  margin: 0;
}

.description {
  font-size: 1.4rem;
}

.descriptionWrapper {
  border-bottom: 1px solid var(--cl-lightGrey);
  margin-bottom: 1.6rem;
  padding: 1.6rem 0;
}

.collapseButton {
  &:focus {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    outline: 0;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}

.list {
  @extend %unstyledList;
}

.listItem {
  margin: 0 0 var(--cl-space-s);
}

.itemLabel {
  font-weight: 600;
  margin-right: var(--cl-space-xs);
}

.itemValue {
  color: var(--cl-deepTeal);
}

.itemIncomplete {
  color: var(--cl-canadaLifeRed);
}

.divider {
  margin-bottom: var(--cl-space-m);
  margin-top: var(--cl-space-m);
}
