
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: grid;
  gap: var(--cl-space-l);

  @include mq(m) {
    gap: var(--cl-space-xl);
  }
}

.title {
  @extend %h3;
  margin-bottom: 2.6rem;
}

.description {
  margin-bottom: 0;
  max-width: 95ch;
}

.formFields {
  display: grid;

  gap: var(--cl-space-l);

  @include mq(m) {
    gap: var(--cl-space-xl);
  }
}

.formField {
  display: grid;
  gap: var(--cl-space-l);
  max-width: 76rem;

  @include mq(m) {
    gap: var(--cl-space-xl);
  }
}

.textContainer {
  max-width: 63rem;
}

.borrower {
  display: grid;
  gap: var(--cl-space-s);

  @include mq(m) {
    gap: var(--cl-space-l);
  }
}

.borrowerHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 4.5rem;
}

.borrowerTitle {
  @extend %h3;
  margin: 0;
}

.addBorrowerButton {
  @include mq($until: m) {
    flex-direction: row-reverse;
    justify-content: space-between;
    text-align: start;
    width: 100%;
  }
}

.removeBorrowerButtonText {
  @include mq($until: s) {
    display: none;
  }
}
