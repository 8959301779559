
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.button {
  align-items: center;
  box-shadow: inset 0 0 0 1px var(--borderColor, transparent);
  display: inline-flex;
  justify-content: center;

  &.large,
  &.small {
    background: var(--bgColor);
    color: var(--textColor);
    gap: 1.7rem;
    outline: none;

    &:hover,
    &:focus {
      background-color: var(--bgHoverColor);
      color: var(--textHoverColor, var(--textColor));
    }

    &:focus {
      background-color: var(--bgFocusColor, var(--bgHoverColor));
      box-shadow: 0 0 0 4px var(--cl-brightGold);
      color: var(--textFocusColor, var(--textHoverColor, var(--textColor)));
    }

    &:focus:not(:focus-visible) {
      background-color: var(--bgHoverColor);
      box-shadow: none;
      color: var(--textHoverColor, var(--textColor));
    }
  }

  &.large {
    @extend %buttonLarge;
    gap: 2rem;
    min-height: 4.5rem;
    padding: 1.1rem 2.5rem 1.2rem;

    svg {
      font-size: 2.2rem;
    }
  }

  &.small {
    @extend %buttonSmall;
    min-height: 3.5rem;
    padding: 0.8rem 1.3rem 0.9rem;

    svg {
      font-size: 1.8rem;
    }
  }

  &.prependIcon {
    flex-direction: row-reverse;
  }

  &.fullWidth {
    width: 100%;

    &.icon {
      justify-content: space-between;
      text-align: start;
    }
  }

  &.alternative,
  &.text {
    min-height: 2.5rem;
    padding: 0;
  }

  svg {
    flex: 0 0 auto;
  }

  &:not(.isDisabled),
  &:not([disabled]) {
    &.primary {
      --bgColor: var(--cl-canadaLifeRed);
      --bgHoverColor: var(--cl-darkRed);
      --textColor: var(--cl-white);
    }

    &.secondary {
      --bgColor: transparent;
      --bgHoverColor: var(--cl-darkRed);
      --textColor: var(--cl-canadaLifeRed);
      --textHoverColor: var(--cl-white);
      --borderColor: var(--cl-canadaLifeRed);
    }

    &.negative {
      --bgColor: var(--cl-white);
      --bgHoverColor: var(--bgColor);
      --textColor: var(--cl-canadaLifeRed);
      --textHoverColor: var(--cl-darkRed);
    }

    &.tertiary {
      --bgColor: transparent;
      --bgHoverColor: var(--cl-darkGrey);
      --textColor: var(--cl-darkGrey);
      --textHoverColor: var(--cl-white);
      --borderColor: var(--cl-darkGrey);
    }

    &.alternative {
      --bgColor: transparent;
      --bgFocusColor: var(--cl-brightGold);
      --textColor: var(--cl-canadaLifeRed);
      --textHoverColor: var(--cl-darkRed);
      --textFocusColor: var(--cl-black);
    }

    &.text {
      --bgColor: transparent;
      --bgFocusColor: var(--cl-brightGold);
      --textColor: var(--cl-canadaLifeRed);
      --textFocusColor: var(--cl-black);
    }

    &.alternative,
    &.text {
      .buttonContent {
        @include text-link;
      }

      &:focus .buttonContent {
        color: var(--textFocusColor);
      }

      &:focus:not(:focus-visible) .buttonContent {
        color: var(--textColor);
      }
    }
  }

  &.isDisabled,
  &[disabled] {
    --bgColor: var(--cl-canadaLifeGrey);
    --bgHoverColor: var(--bgColor);
    --textColor: var(--cl-white);
    --textHoverColor: var(--textColor);
    pointer-events: none;

    &.secondary,
    &.tertiary {
      --bgColor: transparent;
      --borderColor: var(--cl-canadaLifeGrey);
      --textColor: var(--cl-canadaLifeGrey);
    }

    &.alternative,
    &.text {
      --bgColor: transparent;
      --textColor: var(--cl-canadaLifeGrey);
    }
  }
}
